import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import theme from '../theme';
import GlobalStyles from '../GlobalStyles';
import NotFound from 'core/ui/pages/NotFound';
import history from 'core/api/history';


import coreRoutes from 'core/api/routes';
import PublicRoute from '../components/PublicRoute';

const combinedRoutes = [...coreRoutes];

const AppRoot = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
            <div className='App'>
              <Overflow>
                <Switch>
                  {combinedRoutes.map((routeData) => (
                    <PublicRoute key={routeData.path} {...routeData} />
                  ))}
                  <Route component={NotFound} />
                </Switch>
                <GlobalStyles />
              </Overflow>
            </div>
      </Router>
    </ThemeProvider>
  );
};

const Overflow = styled.div`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export default withTranslation()(AppRoot);
