import React from 'react';
import styled, { css } from 'styled-components';
import LEFT_DECORATION from '../../assets/images/decoration_2_1.svg';
import RIGHT_DECORATION from '../../assets/images/decoration_2_2.svg';
import Countdown from '../atoms/Countdown';

import FLERO_LOGO from '../../assets/images/flero_logo_2.png';
import FDATE_LOGO from '../../assets/images/Fdate&eat.png';
import ARROW from '../../assets/images/arrow.avif';
import { Button } from '../atoms';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-color: #f4f4f4; */
  font-family: 'Arial', sans-serif;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;
  overflow: scroll;
`;

const Heading = styled.h1`
  font-size: 36px;
  color: #333;
  text-align: center;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

const Content = styled.p`
  font-size: 18px;
  color: #666;
  text-align: center;
  max-width: 620px;
  margin: 20px 0;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const Decoration = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  ${(p) =>
    p.isLeft &&
    css`
      top: auto;
      right: auto;
      left: 0;
      bottom: 0;
    `}
  ${(p) => p.theme.max('lg')`
    width: 15%;
  `}
`;

const Img = styled.img`
  width: 300px;
  height: 40px;
  object-fit: contain;
  /* border-radius: 10%; */

  overflow: hidden;
  ${(p) => p.theme.max('md')`
    object-fit: contain;
    width: 100px;
    `}
`;

const Img2 = styled.img`
  width: 100px;
  height: 25px;
  overflow: hidden;
  transform: rotate(180deg);
  ${(p) => p.theme.max('md')`
    object-fit: contain;
    width: 100%;
    height: 80%;
    width: 30px`}
`;

const Img3 = styled.img`
  width: 300px;
  height: 40px;
  /* border-radius: 10%; */
  overflow: hidden;
  ${(p) => p.theme.max('md')`
    object-fit: contain;
    width: 150px;
    `}
`;

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 200px;
  ${(p) => p.theme.max('md')`
  gap: 10px;
  `}
`;

const ConstructionPage = () => {
  const redirect = () => {
    window.location.href = 'https://f-date.ru/';
  };

  const { timeLeft } = Countdown({ seconds: 30, onComplete: redirect });
  return (
    <PageContainer>
      <Decoration isLeft src={LEFT_DECORATION} />
      <Decoration src={RIGHT_DECORATION} />
      <ImgContainer>
        <Img src={FLERO_LOGO} />
        <Img2 src={ARROW} />
        <Img3 src={FDATE_LOGO} />
      </ImgContainer>
      <Heading>Мы переехали!</Heading>
      <Content>
        Что может быть лучше качественных знакомств?!
        <br />
        Конечно! Совместные гастрономические встречи в лучших ресторанах, кафе и
        не только!
        <br />
        <br />
        <Heading>Flero теперь F Date&eat</Heading>
        А название теперь говорит само за себя!
        <br />
        <br />
        Выбирай понравившиеся или уже любимые заведения, находи себе пару, и
        приглашай на свидания! Или пригласят тебя!
        <br />
        <br />
        Через {timeLeft} секунд ты окажешься на новом сайте и узнаешь всё о
        нашем большом обновлении!
        <br />
        <br />
        <Heading>Либо переходи по ссылке</Heading>
        <br />
        <Button onClick={redirect}>Перейти на новый сайт</Button>
      </Content>
    </PageContainer>
  );
};

export default ConstructionPage;
