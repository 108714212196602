import PublicLayout from 'core/ui/Layouts/PublicLayout';

import ConstructionPage from '../ui/pages/ConstructionPage';

export default [
  {
    component: ConstructionPage,
    layout: PublicLayout,
    isPublic: true,
    redirectLogged: true,
    path: '/'
  },
];